exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banner-js": () => import("./../../../src/pages/banner.js" /* webpackChunkName: "component---src-pages-banner-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-company-about-us-js": () => import("./../../../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-locations-adelaide-js": () => import("./../../../src/pages/company/locations/adelaide.js" /* webpackChunkName: "component---src-pages-company-locations-adelaide-js" */),
  "component---src-pages-company-locations-brisbane-js": () => import("./../../../src/pages/company/locations/brisbane.js" /* webpackChunkName: "component---src-pages-company-locations-brisbane-js" */),
  "component---src-pages-company-locations-js": () => import("./../../../src/pages/company/locations.js" /* webpackChunkName: "component---src-pages-company-locations-js" */),
  "component---src-pages-company-locations-melbourne-js": () => import("./../../../src/pages/company/locations/melbourne.js" /* webpackChunkName: "component---src-pages-company-locations-melbourne-js" */),
  "component---src-pages-company-locations-sydney-js": () => import("./../../../src/pages/company/locations/sydney.js" /* webpackChunkName: "component---src-pages-company-locations-sydney-js" */),
  "component---src-pages-company-partners-carriers-js": () => import("./../../../src/pages/company/partners/carriers.js" /* webpackChunkName: "component---src-pages-company-partners-carriers-js" */),
  "component---src-pages-company-partners-index-js": () => import("./../../../src/pages/company/partners/index.js" /* webpackChunkName: "component---src-pages-company-partners-index-js" */),
  "component---src-pages-company-partners-technology-js": () => import("./../../../src/pages/company/partners/technology.js" /* webpackChunkName: "component---src-pages-company-partners-technology-js" */),
  "component---src-pages-company-technology-index-js": () => import("./../../../src/pages/company/technology/index.js" /* webpackChunkName: "component---src-pages-company-technology-index-js" */),
  "component---src-pages-company-technology-openfreight-js": () => import("./../../../src/pages/company/technology/openfreight.js" /* webpackChunkName: "component---src-pages-company-technology-openfreight-js" */),
  "component---src-pages-company-technology-trackmyparcel-js": () => import("./../../../src/pages/company/technology/trackmyparcel.js" /* webpackChunkName: "component---src-pages-company-technology-trackmyparcel-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-b-2-b-js": () => import("./../../../src/pages/industries/b2b.js" /* webpackChunkName: "component---src-pages-industries-b-2-b-js" */),
  "component---src-pages-industries-ecommerce-js": () => import("./../../../src/pages/industries/ecommerce.js" /* webpackChunkName: "component---src-pages-industries-ecommerce-js" */),
  "component---src-pages-industries-enterprise-js": () => import("./../../../src/pages/industries/enterprise.js" /* webpackChunkName: "component---src-pages-industries-enterprise-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-4-pl-freight-management-js": () => import("./../../../src/pages/services/4pl-&-freight-management.js" /* webpackChunkName: "component---src-pages-services-4-pl-freight-management-js" */),
  "component---src-pages-services-integration-automation-js": () => import("./../../../src/pages/services/integration-&-automation.js" /* webpackChunkName: "component---src-pages-services-integration-automation-js" */),
  "component---src-pages-services-tracking-reporting-js": () => import("./../../../src/pages/services/tracking-&-reporting.js" /* webpackChunkName: "component---src-pages-services-tracking-reporting-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

