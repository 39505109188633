import { graphql, useStaticQuery } from "gatsby";
import React, { createContext, useState } from "react";

const AppContext = createContext({ socialLinks: "" });

export const AppProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        social {
          youtube
          twitter
          linkedIn
          facebook
        }
        seo {
          metaTitle
          metaDescription
          _rawOgImage
        }
      }
    }
  `);

  const social = data?.sanitySiteSettings?.social;
  const [socialLinks, setSocialLinks] = useState(social);

  return (
    <AppContext.Provider value={{ socialLinks }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
